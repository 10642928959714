import React, { useState } from "react";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import Loader from "components/loader";

const SourceBox = ({
  sourceText,
  setSourceText,
  outputText,
  setOutputText,
  textareaRef,
  outputRef,
}) => {
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const words = sourceText
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0);
    // const handleTextChange = (e) => {
    //   const text = e.target.value;
    //   const words = sourceText
    //   .trim()
    //   .split(/\s+/)
    //   .filter((word) => word.length > 0);
  
    //   if (words.length > 1000) {
    //     e.target.value = words.slice(0, 1000).join(' ');
    //     return;
    //   }
    //   setWordCount(words.length);
    //   setSourceText(e.target.value);
    //   setOutputText("");
    //   adjustHeight();
    // };
    const handleTextChange = (e) => {
      const text = e.target.value;
      const words = text
        .trim()
        .split(/\s+/)
        .filter((word) => word.length > 0);
    
      if (words.length > 1000) {
        e.target.value = words.slice(0, 1000).join(" ");
        return;
      }
    
      setWordCount(words.length);
      setSourceText(text);  
      setOutputText("");
      adjustHeight();
    };
    
    const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 380);
    textarea.style.height = `${newHeight}px`;
  };

  const getAnonymizeText = async () => {
    try {
      setLoader(true);
      const response  = await http().post(
        endpoints.forms.aiTools,
        {
           tool_name: "anonymizeData",
          user_text: sourceText,
        }
      );
      setOutputText(response?.result?.text);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
 
  return (
    <div className="shadow-box p-4 flex-1 text-gray26 bg-white rounded-lg font-opensans">
      <div className="flex justify-between items-center">
        <span className="font-bold text-lg"> Source Text</span>
        <p className="text-base text-[#9C9C9C] ">
          {wordCount}/1000 words
        </p>
      </div>
      <textarea
        ref={textareaRef}
        value={sourceText}
        onChange={handleTextChange}
        // onChange={(e) => {
        //   setOutputText("");
        //   setSourceText(e.target.value);
        //   adjustHeight();
        // }}
        className={`anonymize_tool  outline-none w-full my-5 resize-none text-base pr-2 border-none ${
          outputRef?.current?.offsetHeight
            ? `min-h-[${outputRef?.current?.offsetHeight + 54}px]`
            : "min-h-[175px]"
        }  max-h-[400px]`}
        placeholder="Enter your text here"
      />
      <div className="">
        <div className="bg-[#F0F5FF] rounded-lg p-2">
          <div className="flex md:flex-row flex-col gap-2.5">
            <button
              onClick={() => {
                setSourceText("");
                setOutputText("");
                setWordCount(0)
              }}
              className={` flex-1 flex items-center justify-center px-5 py-3 rounded-lg gap-3 border ${
                sourceText
                  ? "border-[#5B93FF] text-[#5B93FF]"
                  : "border-[#B0B0B0] text-[#B0B0B0]"
              }`}
            >
              {!sourceText ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.1492 12.413L9.97513 5.23889M20.2238 20.6119H6.90053M9.91083 19.6513L18.795 10.7671C20.0126 9.54954 20.6214 8.94075 20.8495 8.23873C21.0502 7.62121 21.0502 6.95603 20.8495 6.33852C20.6214 5.6365 20.0126 5.0277 18.795 3.81011L18.578 3.59307C17.3604 2.37547 16.7516 1.76667 16.0496 1.53857C15.4321 1.33793 14.7669 1.33793 14.1494 1.53857C13.4473 1.76667 12.8385 2.37547 11.621 3.59307L3.20497 12.009C1.98738 13.2266 1.37858 13.8354 1.15048 14.5375C0.949839 15.155 0.949839 15.8202 1.15048 16.4377C1.37858 17.1397 1.98738 17.7485 3.20497 18.9661L3.89023 19.6513C4.24473 20.0058 4.42199 20.1831 4.62884 20.3098C4.81223 20.4222 5.01217 20.5051 5.22132 20.5553C5.45722 20.6119 5.70789 20.6119 6.20924 20.6119H7.59182C8.09317 20.6119 8.34384 20.6119 8.57974 20.5553C8.78889 20.5051 8.98883 20.4222 9.17222 20.3098C9.37907 20.1831 9.55633 20.0058 9.91083 19.6513Z"
                    stroke="#B0B0B0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.1492 12.413L9.97513 5.23889M20.2238 20.6119H6.90053M9.91083 19.6513L18.795 10.7671C20.0126 9.54955 20.6214 8.94075 20.8495 8.23873C21.0502 7.62122 21.0502 6.95604 20.8495 6.33852C20.6214 5.6365 20.0126 5.02771 18.795 3.81011L18.578 3.59307C17.3604 2.37547 16.7516 1.76668 16.0496 1.53858C15.4321 1.33794 14.7669 1.33794 14.1494 1.53858C13.4473 1.76668 12.8385 2.37548 11.621 3.59307L3.20497 12.009C1.98738 13.2266 1.37858 13.8354 1.15048 14.5375C0.949839 15.155 0.949839 15.8202 1.15048 16.4377C1.37858 17.1397 1.98738 17.7485 3.20497 18.9661L3.89023 19.6513C4.24473 20.0058 4.42199 20.1831 4.62884 20.3099C4.81223 20.4222 5.01217 20.5051 5.22132 20.5553C5.45722 20.6119 5.70789 20.6119 6.20924 20.6119H7.59182C8.09317 20.6119 8.34384 20.6119 8.57974 20.5553C8.78889 20.5051 8.98883 20.4222 9.17222 20.3099C9.37907 20.1831 9.55633 20.0058 9.91083 19.6513Z"
                    stroke="#5B93FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              Clear Text
            </button>
            <button
              disabled={!sourceText}
              onClick={getAnonymizeText}
              className={` outline-none flex-1 flex items-center justify-center px-6 py-3 rounded-lg gap-3 border ${
                !outputText
                  ? "bg-[#5B93FF] border-[#5B93FF]  text-white"
                  : "bg-[#E8E8E8] border-[#E8E8E8]"
              }`}
            >
              {loader ? <Loader /> : " Anonymize my data"}
            </button>
          </div>
          <p className="text-[12px] mt-2 text-[#575757]">
          This AI tool may not catch all sensitive information. Check the output before sharing.

          </p>
        </div>
      </div>
    </div>
  );
};

export default SourceBox;
